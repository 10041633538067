<template>
  <div class="card">
    <div class="card-list">
      <button
        class="card-item"
        v-for="item in correctionHistory"
        :key="item.id"
        @click="showCorrection(item)"
        :id="'card-item' + item.id"
      >
        <div class="card-item__datetime">
          <div class="card-item__date">
            {{ formatDate(item.date) }}
          </div>
          <div class="card-item__time">
            {{ formatTime(item.date) }}
          </div>
        </div>
        <div class="card-item__author">
          <p class="card-item__author-text">{{ item.author }}</p>
        </div>
      </button>
    </div>
    <div v-if="isFirstCorrectionImageShown" style="width: 100%; text-align: center">
      <div class="correction-info-first-correction__image" />
      <p class="correction-info-first-correction__text">
        Это первая версия документа
      </p>
    </div>
    <div v-else>
      <div class="correction-info" v-if="correction">
        <div
          class="correction-info__block"
          v-for="item in correction.fields"
          :key="item.fieldName"
        >
          <div class="correction-info__field-name">{{ item.fieldName }}</div>
          <div class="correction-info__value old">{{ item.oldValue }}</div>
          <div class="correction-info__value new">{{ item.newValue }}</div>
        </div>
        <div
          class="correction-info__field-name file-field"
          v-if="correction.removedFiles?.length"
        >
          Удаленные файлы
        </div>
        <div
          class="correction-info__block file-block"
          v-if="correction.removedFiles?.length"
        >
          <div
            class="correction-info__value old files"
            v-for="file in correction.removedFiles"
            :key="file.fileName"
          >
            <BaseFile :file="file"></BaseFile>
          </div>
        </div>
        <div
          class="correction-info__field-name file-field"
          v-if="correction.addedFiles?.length"
        >
          Добавленные файлы
        </div>
        <div
          class="correction-info__block file-block"
          v-if="correction.addedFiles?.length"
        >
          <div
            class="correction-info__value new files"
            v-for="file in correction.addedFiles"
            :key="file.fileName"
          >
            <BaseFile :file="file"></BaseFile>
          </div>
        </div>

        <div
          class="correction-info__field-name file-field"
          v-if="correction.removedTprFiles?.length"
        >
          Удаленные файлы территориального планирования
        </div>
        <div
          class="correction-info__block file-block"
          v-if="correction.removedTprFiles?.length"
        >
          <div
            class="correction-info__value old files"
            v-for="file in correction.removedTprFiles"
            :key="file.fileName"
          >
            <BaseFile :file="file"></BaseFile>
          </div>
        </div>
        <div
          class="correction-info__field-name file-field"
          v-if="correction.addedTprFiles?.length"
        >
          Добавленные файлы территориального планирования
        </div>
        <div
          class="correction-info__block file-block"
          v-if="correction.addedTprFiles?.length"
        >
          <div
            class="correction-info__value new files"
            v-for="file in correction.addedTprFiles"
            :key="file.fileName"
          >
            <BaseFile :file="file"></BaseFile>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from "vue";
import useCorrectionHistory from "./hooks/correctionHistory";
import BaseFile from "../basic/files/BaseFile.vue";
export default {
  props: {
    correctionHistory: {
      type: Array,
      default: () => [],
    },
    sectionNumber: {
      type: String,
    },
    documentNumber: {
      type: String,
    },
  },
  components: {
    BaseFile,
  },
  setup(props, { emit }) {
    const correction = ref(null);
    const isFirstCorrectionImageShown = ref(false);
    const { getCorrectionInfo } = useCorrectionHistory(
      props.documentNumber,
      props.sectionNumber
    );
    const firstItemId = ref(null);

    getCorrectionInfo(props.correctionHistory[0].id, props.sectionNumber).then(
      (result) => {
        correction.value = result;
      }
    );

    function formatDate(date) {
      if (!date) return "";

      return `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
    }

    function formatTime(date) {
      if (!date) return "";

      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }

    function showCorrection(item) {
      if(item.isFirst)
      {
        isFirstCorrectionImageShown.value = true
        correction.value = null;
      }
      else
      {
        isFirstCorrectionImageShown.value = false;
        correction.value = getCorrectionInfo(item.id, props.sectionNumber).then(
          (result) => {
            correction.value = result;
          }
        );
      }
      emit("currentCorrection", { id: item.id });
    }

    onMounted(() => {
      firstItemId.value = props.correctionHistory[0].id;
      document.getElementById("card-item" + firstItemId.value).focus();
    });

    return {
      correction,
      formatDate,
      formatTime,
      showCorrection,
      isFirstCorrectionImageShown
    };
  },
};
</script>

<style scoped>
.old {
  text-decoration: line-through;
}

.correction-info-first-correction__image {
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  width: 325px;
  height: 232px;
  background-image: url("../../assets/svg/first-correction.svg");
}

.correction-info-first-correction__text {
  margin-bottom: 24px;
  font-size: 16px;
}

.correction-info__field-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
  letter-spacing: 0.04em;
  color: #90a0b7;
}

.correction-info__value {
  font-size: 16px;
  line-height: 20px;
  color: #465362;
  margin-bottom: 6px;
}

.card {
  display: flex;
  height: 100%;
}

.card-list {
  overflow-y: scroll;
  width: 20%;
  min-width: 240px;
}

.correction-info {
  align-items: left;
  margin-left: 48px;
  margin-right: 24px;
  overflow-y: scroll;
  width: 73%;
  font-family: Source Sans Pro;
  font-style: normal;
}

.card-item {
  background: #fefefe;
  border: 1px solid #dfe2e7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 16px;
  position: static;
  width: 220px;
  margin: 16px 0px;
  color: #465362;
  margin-bottom: 16px;
  margin-top: 0px;
  text-align: left;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
}

.card-item:hover {
  cursor: pointer;
  border: 1px solid #518cff;
}

.card-item:focus {
  background: #eaf1ff;
  color: #518cff;
  border: 0px;
}

.card-item:focus .card-item__author {
  color: #518cff;
}

.card-item__author {
  width: 160px;
  height: 36px;
  font-weight: normal;
  letter-spacing: 0.02em;
  color: #90a0b7;
  display: flex;
}

.card-item__datetime {
  display: flex;
  margin-bottom: 16px;
}

.card-item__date {
  width: 100%;
}

.correction-info__block {
  margin-bottom: 24px;
}

.file-field {
  margin-bottom: 12px;
}

.file-block {
  width: fit-content;
}

.files {
  margin-bottom: 12px;
}

.card-item__author-text {
  margin: 0px;
  margin-top: auto;
}
</style>
