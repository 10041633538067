<template>
  <div class="page">
    <page-header class="container">
      <template #title>
        <div class="title">{{ state.document.fullname }}</div>
        <signature-info
          class="signatureInfo"
          v-if="
            state.document.fullname &&
              state.signatureInfo &&
              state.signatureInfo.certificateInfo
          "
          v-model="state.signatureInfo"
        ></signature-info>
      </template>
      <template #subtitle>
        <BackLink>{{ backLinkName }}</BackLink>
      </template>
      <template #buttons>
        <button
          class="button button--outline button-back"
          @click="showModal"
          :disabled="!localCorrectionHistory.length"
        >
          История исправлений
        </button>
        <a
          class="button button--primary group__item"
          @click="fixErrors"
          v-if="haveEditRight"
          >Исправить ошибку</a
        >
      </template>
    </page-header>
    <div class="box container">
      <DocumentProperties :document="state.document"></DocumentProperties>
    </div>
  </div>

  <modal-window
    :visible="isModalWindowVisible"
    @close="closeModal"
    :wide="true"
  >
    <template #title>История исправлений</template>
    <CorrectionHistory
      :correctionHistory="localCorrectionHistory"
      :sectionNumber="sectionNumber"
      :documentNumber="docNumber"
      v-on:currentCorrection="getCurrentCorrection($event)"
    ></CorrectionHistory>
    <template #footer>
      <div class="group">
        <button
          class="button button--outline group__item"
          @click="showDocumentWithCorrection"
        >
          Просмотреть документ
        </button>
        <button class="button button--primary group__item" @click="closeModal">
          Закрыть
        </button>
      </div>
    </template>
  </modal-window>
</template>
<script>
import DocumentProperties from "./DocumentProperties";
import BackLink from "../basic/BackLink";
import beforeRouteEnter from "./hooks/beforeRouterEnter";
import useSectionDocument from "./hooks/sectionDocument";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { ref, watch, onBeforeMount } from "vue";
import useCorrectionHistory from "./hooks/correctionHistory";
import CorrectionHistory from "./CorrectionHistory.vue";
import SignatureInfo from "./SignatureInfo.vue";
import useAuthorization from "@/authorization";
import useProjectAlias from "@/hooks/projectAlias";
import useDocumentSignatureInfo from "./hooks/documentSignature";

export default {
  components: {
    DocumentProperties,
    BackLink,
    CorrectionHistory,
    SignatureInfo,
  },
  setup() {
    const { canEdit } = useAuthorization();
    const { setProjectByDocument } = useProjectAlias();

    const router = useRouter();
    const route = useRoute();

    const haveEditRight = canEdit();

    const backLinkName = ref("");

    const docNumber = ref(route.params.number);
    const sectionNumber = GetSectionFromRegNumber(docNumber.value);

    const state = ref({ document: null, signatureInfo: null });
    const localCorrectionHistory = ref([]);

    const { GetSignatureInfoForDocument } = useDocumentSignatureInfo();

    const {
      correctionHistory,
      getCorrectionInfo,
      getDocumentWithCorrection,
      fetchCorrectionHistory,
    } = useCorrectionHistory(docNumber.value, sectionNumber);
    localCorrectionHistory.value = correctionHistory;
    const isModalWindowVisible = ref(false);
    const currentCorrection = ref(null);

    watch(
      () => correctionHistory.value,
      (newValue) => {
        currentCorrection.value = newValue[0];
      }
    );

    function showModal() {
      isModalWindowVisible.value = true;
    }

    function closeModal() {
      isModalWindowVisible.value = false;
    }

    function fixErrors() {
      setProjectByDocument(docNumber.value).then(() => {
        router.push({
        name: "fixErrors",
        params: { number: docNumber.value },
        });
      })
    }

    function showDocumentWithCorrection() {
      var route = router.resolve({
        name: "documentWithCorrection",
        params: {
          number: docNumber.value,
          correctionId: currentCorrection.value.id,
        },
      });

      window.open(route.href, "_blank");
    }

    function getCurrentCorrection(value) {
      currentCorrection.value = value;
    }

    function UpdateDocument(number) {
      state.value.document = useSectionDocument(number);
      GetSignatureInfoForDocument(number).then((result) => {
        state.value.signatureInfo = result;
      });
    }


    function UpdateDocumentHistory(number) {
      docNumber.value = number;
      fetchCorrectionHistory(docNumber.value, sectionNumber);
      localCorrectionHistory.value = correctionHistory;
    }

    function GetSectionFromRegNumber(number) {
      return new RegExp(/([^-]*)-([^-]*)-([^-]*)-([^-]*)/).exec(number)[2];
    }

    onBeforeMount(async () => {
      UpdateDocument(docNumber.value);
      UpdateDocumentHistory(docNumber.value);
    });

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.number !== from.params.number) {
        UpdateDocument(to.params.number);
        UpdateDocumentHistory(to.params.number);
      }
    });
    return {
      state,
      backLinkName,
      localCorrectionHistory: localCorrectionHistory.value,
      isModalWindowVisible,
      sectionNumber,
      docNumber,
      fixErrors,
      showDocumentWithCorrection,
      getCorrectionInfo,
      getDocumentWithCorrection,
      showModal,
      closeModal,
      getCurrentCorrection,
      haveEditRight,
    };
  },
  beforeRouteEnter,
};
</script>
<style scoped>
.title,
.signatureInfo {
  display: inline;
  top: 5px;
}
</style>
