import { ref } from 'vue';
import SectionDocument from '../models/sectionDocument';
import SectionDocumentsProxy from '../proxies/sectionDocumentsProxy';

export default function useSectionDocument(documentNumber) {
  const document = ref(new SectionDocument());

  fetch(documentNumber);

  async function fetch(documentNumber) {
    document.value = await SectionDocumentsProxy.getSectionDocument(
      documentNumber
    );
  }

  return document;
}
